import { Component, createContext } from "react";
import { mainClient } from "../network/mainClient";
import { authStorage } from "./authStorage";
import { TokenData } from "./types";
import { getSortAscending } from "../modules/verificationRequest/utils/getSort";
import { TCompany } from "../modules/company/models";

export type AuthState = {
  loading: boolean;
  loaded: boolean;
  authenticated: boolean;
  authorizedCompanyId: string | null;
  companies: { items: TCompany[] } | null;
};

export type AuthContextValue = AuthState & {
  requestSms: typeof mainClient.auth.requestSms;
  authWithCode: typeof mainClient.auth.authWithCode;
  setTokenData: (tokenData: TokenData) => void;
  logout: () => Promise<boolean>;
  setCompanies: (companies: { items: TCompany[] } | null) => void;
};

export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue
);

export class AuthProvider extends Component<{}, AuthState> {
  state = {
    loading: true,
    loaded: false,
    authenticated: false,
    authorizedCompanyId: null,
    companies: null,
  };

  componentDidMount() {
    this.restore();
  }

  restore = async () => {
    const data = await authStorage.getAuthData();
    this.setState({
      loading: false,
      loaded: true,
      authenticated: data !== null,
      authorizedCompanyId: data && data.companyId ? data.companyId : null,
      companies: null,
    });
  };

  requestSms = async (phone: string) => {
    return await mainClient.auth.requestSms(phone);
  };

  authWithCode = async (phone: string, code: string, salt: string) => {
    const result = await mainClient.auth.authWithCode(phone, code, salt);
    authStorage.setAuthData(result);
    this.setState({
      authenticated: true,
    });
    return result;
  };

  logout = async () => {
    authStorage.clear();
    this.setState({
      authenticated: false,
      authorizedCompanyId: null,
    });
    return true;
  };

  setTokenData = (tokenData: TokenData) => {
    if (!tokenData) {
      throw new Error("Token Data not provided");
    }
    authStorage.setAuthData(tokenData);
    this.setState({
      authorizedCompanyId: tokenData.companyId || null,
    });
  };

  setCompanies = (data: any | null) => {
    this.setState({
      companies: { items: getSortAscending(data.items, "name") },
    });
  };

  render() {
    const { children } = this.props;

    const contextValue: AuthContextValue = {
      ...this.state,
      requestSms: this.requestSms,
      authWithCode: this.authWithCode,
      setTokenData: this.setTokenData,
      setCompanies: this.setCompanies,
      logout: this.logout,
    };

    return (
      <AuthContext.Provider value={contextValue}>
        {children}
      </AuthContext.Provider>
    );
  }
}
