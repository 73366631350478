import { TVerificationRequest } from "../models";

export const getTitle = (
  id: string,
  data?: TVerificationRequest | null
): string => {
  if (
    data?.additionalItems?.userInformationParsed?.first_name &&
    data?.additionalItems?.userInformationParsed?.last_name
  ) {
    return `${data.additionalItems.userInformationParsed.first_name} ${data.additionalItems.userInformationParsed.last_name}`;
  }

  return id;
};
