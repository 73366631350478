import { useEffect, useState } from "react";
import { ConfirmationDialog } from "../../../ui/components/ConfirmationDialog";
import { DeclineReasonForm } from "../DeclineReasonForm";
import { useRejectReason } from "../../hooks/useRejectReason";
import { useChangeRejectReason } from "../../hooks/useChangeRejectReason";

export type Props = {
  onClose: (reason: "changeRejectReason" | "cancel") => void;
  open: boolean;
  statusReason: string;
  verificationId: string;
  request: () => void;
};

export const ChangeRejectReasonDialog = (props: Props) => {
  const { onClose, open, verificationId, statusReason } = props;
  const [result, loading, error, changeRejectReason] = useChangeRejectReason();

  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  const [changeDecline, validationErrors, setValidationErrors] =
    useRejectReason(reason, otherReason, verificationId, changeRejectReason);

  const handleClose = () => {
    setValidationErrors({});
    onClose("cancel");
  };

  useEffect(() => {
    if (open) {
      const reasons = statusReason.split(":");
      setReason(reasons[0]);
      setOtherReason(reasons[1]);
    }
  }, [statusReason, open]);

  useEffect(() => {
    if (result) {
      onClose("changeRejectReason");
    }
  }, [result, onClose]);

  return (
    <ConfirmationDialog
      title='Reason for rejection'
      error={error}
      confirmText='Save'
      confirmAction={changeDecline}
      disabled={loading}
      onClose={handleClose}
      isOpen={open}
      confirmButtonColor='primary'
    >
      <DeclineReasonForm
        reason={reason}
        otherReason={otherReason}
        setReason={setReason}
        setOtherReason={setOtherReason}
        validationErrors={validationErrors}
      />
    </ConfirmationDialog>
  );
};
