import { TPagingResponse } from "../../../models/PagingResponse";
import { request } from "../../../network/mainClient/request";
import { IViewKeys } from "../../company/models";
import { TId } from "../../ui";
import { TApp, TAppSettings, TCreateAppData, TSdkSettings } from "../models";

export const getAppList = async (): Promise<TPagingResponse<TApp>> => {
  const response = await request({
    path: "/api/v1/verification-service/app?limit=10",
    method: "GET",
  });

  if (response.ok) {
    return {
      items: response.data.apps,
    };
  }

  throw new Error(response.errorMessage);
};

export const getApp = async (id: string): Promise<TApp> => {
  const response = await request({
    path: `/api/v1/verification-service/app/${id}`,
    method: "GET",
  });

  if (response.ok) {
    return response.data.app;
  }

  throw new Error(response.errorMessage);
};

export const createApp = async (data: TCreateAppData): Promise<TApp> => {
  const response = await request({
    path: "/api/v1/verification-service/app",
    method: "POST",
    body: data,
  });

  if (response.ok) {
    return response.data.app;
  }

  throw new Error(response.errorMessage);
};

export const getAppSettings = async (id: string): Promise<TAppSettings> => {
  const response = await request({
    path: `/api/v1/verification-service/app/${id}/settings`,
    method: "GET",
  });

  if (response.ok) {
    return response.data.appSettings;
  }

  throw new Error(response.errorMessage);
};

export const updateAppSettings = async (
  appId: string,
  data: TAppSettings
): Promise<TAppSettings> => {
  const response = await request({
    path: `/api/v1/verification-service/app/${appId}/settings`,
    method: "POST",
    body: data,
  });

  if (response.ok) {
    return response.data.appSettings;
  }

  throw new Error(response.errorMessage);
};

export const updateSdkSettings = async (
  appId: string,
  data: TSdkSettings
): Promise<TAppSettings> => {
  const response = await request({
    path: `/api/v1/sdk/app/${appId}/settings`,
    method: "POST",
    body: data,
  });

  if (response.ok) {
    return response.data.settings;
  }

  throw new Error(response.errorMessage);
};

export const getAppKeys = async (app_id: TId): Promise<IViewKeys> => {
  const response = await request({
    path: `/api/v1/user-service/app/${app_id}/keys`,
    method: "GET",
  });

  if (response.ok) {
    return response.data.keys;
  }

  throw new Error(response.errorMessage);
};
