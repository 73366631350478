import { useCallback, useEffect, useState } from "react";
import { ValidationErrors } from "final-form";
import Dialog from "@mui/material/Dialog";
import { SelectData } from "mui-rff";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useRegexActions } from "../../hooks/useRegexCreate";
import { ICountryRegex, ICreateCoutryRegex } from "../../../company/models";
import { isEmpty } from "lodash";
import { countrySelectData } from "../../../../data/countriesData";

export type TProps = {
  onClose: (success: boolean) => void;
  availCountries: SelectData[];
  actionType: "edit" | "create";
  selectedRegex: ICountryRegex | null;
  open: boolean;
};

export const CreateRegexDialog = (props: TProps) => {
  const { onClose, open, availCountries, selectedRegex, actionType } = props;
  const [result, loading, error, request] = useRegexActions();
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: boolean;
  }>({});
  const [country, setCountry] = useState("");
  const [regexp, setRegexp] = useState("");
  const [example, setExample] = useState("");
  const [type, setType] = useState("TIN");

  const handleClear = () => {
    setCountry("");
    setRegexp("");
    setExample("");
    setType("");
    setValidationErrors({});
  };

  useEffect(() => {
    if (!type) {
      setType("TIN");
    }
  }, [type]);

  useEffect(() => {
    if (selectedRegex) {
      setCountry(selectedRegex.country);
      setRegexp(selectedRegex.regexp);
      setExample(selectedRegex.example);
      setType(selectedRegex.type);
    }
  }, [selectedRegex]);

  useEffect(() => {
    if (result) {
      handleClear();
      onClose(true);
    }
  }, [result, onClose]);

  const handleCancel = useCallback(() => {
    handleClear();
    onClose(false);
  }, [onClose]);

  const validate = useCallback((formData: ICreateCoutryRegex) => {
    const errors: ValidationErrors = {};
    setValidationErrors({});
    if (!formData.country) {
      errors.country = true;
    }
    if (!formData.regexp) {
      errors.regexp = true;
    }
    if (!formData.type) {
      errors.type = true;
    }
    setValidationErrors(errors);
    return errors;
  }, []);

  const onSubmit = useCallback(
    (formData: ICreateCoutryRegex) => {
      const errors = validate(formData);
      if (isEmpty(errors)) {
        request(formData, actionType, selectedRegex ? selectedRegex?.id : "");
      }
    },
    [request, validate, actionType, selectedRegex]
  );

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth='xs'
      open={open}
      id='create-app-dialog'
      keepMounted
    >
      <DialogTitle sx={{ textTransform: "capitalize" }}>
        {actionType} regex
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <FormControl error={validationErrors.country} required>
            <InputLabel>Country</InputLabel>
            <Select
              disabled={actionType === "edit"}
              variant='filled'
              label='Country'
              value={country}
              onChange={(e) => {
                delete validationErrors.country;
                return setCountry(e.target.value);
              }}
              size='small'
            >
              {(actionType === "create"
                ? availCountries
                : countrySelectData
              ).map((country) => (
                <MenuItem value={country.value}>{country.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            error={validationErrors.regexp}
            label='Regexp'
            value={regexp}
            onChange={(e) => {
              delete validationErrors.regexp;
              return setRegexp(e.target.value);
            }}
            required
            size='small'
          />
          <TextField
            label='Example'
            value={example}
            onChange={(e) => setExample(e.target.value)}
            size='small'
          />
          <FormControl error={validationErrors.type} required>
            <InputLabel>Type</InputLabel>
            <Select
              variant='filled'
              label='Type'
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
              size='small'
            >
              {["TIN"].map((type) => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit({ country, example, regexp, type })}
          disabled={loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
