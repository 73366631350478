import { useCallback, useEffect, useState } from "react";
import {
  addComplianceComment,
  getVerificationRequestComments,
} from "../network";

export const useComplianceComments = (
  verificationId: string
): [
  { [key: string]: string },
  boolean,
  Error | null,
  () => void,
  (userDocumentId: string, comment: string) => void
] => {
  const [result, setResult] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const getComments = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getVerificationRequestComments(verificationId);
      setResult(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error("Unknown error"));
      }
    }
    setIsLoading(false);
  }, [verificationId]);

  const addComment = useCallback(
    async (userDocumentId: string, comment: string) => {
      setIsLoading(true);
      setError(null);
      try {
        await addComplianceComment(verificationId, userDocumentId, comment);
        await getComments();
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error("Unknown error"));
        }
      }
      setIsLoading(false);
    },
    [verificationId, getComments]
  );

  useEffect(() => {
    getComments();
  }, [getComments]);

  return [result, isLoading, error, getComments, addComment];
};
