import { useMemo } from "react";
import { FilledTextArea } from "../../../../components/FilledTextArea";
import { getCountryFromCode } from "../../../../utils/getCountryFromCode";

export const SecondNationality = (props: {
  onChange: any;
  name: string;
  fields: any;
  label: string;
  disabled?: boolean;
}) => {
  const { onChange, fields, name, label, disabled } = props;

  const country = useMemo(() => {
    return getCountryFromCode(fields[name]);
  }, [fields, name]);

  return (
    <FilledTextArea
      name={name}
      label={country || !fields[name] ? "Second Nationality" : label}
      inputProps={{ onChange, disabled }}
      value={country ?? fields[name] ?? ""}
    />
  );
};
