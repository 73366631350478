import { TPagingResponse } from "../../../models/PagingResponse";
import { request } from "../../../network/mainClient/request";
import { ICountryRegex, ICreateCoutryRegex } from "../../company/models";
import { getSortAscending } from "../../verificationRequest/utils/getSort";
import { ICountriesRegex } from "../models";

export const getRegexList = async (): Promise<
  TPagingResponse<ICountryRegex, string>
> => {
  const response = await request({
    path: "/api/v1/user-service/countryregex",
    method: "GET",
  });

  if (response.ok) {
    const filteredResponse = response.data.countryRegex.filter(
      (regexItem: ICountryRegex) => regexItem.country
    );
    return {
      items: getSortAscending(filteredResponse, "country"),
      subItems: filteredResponse.map(
        (regexItem: ICountryRegex) => regexItem.country
      ),
    };
  }

  throw new Error(response.errorMessage);
};

export const getRegexCountries = async (): Promise<ICountriesRegex> => {
  const response = await request({
    path: "/api/v1/user-service/countryregex",
    method: "GET",
  });

  if (response.ok) {
    const countriesRegex: ICountriesRegex = {};
    for (let regexItem of response.data.countryRegex) {
      countriesRegex[regexItem.country] = regexItem;
    }
    return countriesRegex;
  }

  throw new Error(response.errorMessage);
};

export const updateRegex = async (
  id: string,
  body: ICreateCoutryRegex
): Promise<TPagingResponse<ICountryRegex>> => {
  const response = await request({
    path: `/api/v1/user-service/countryregex/${id}`,
    method: "PATCH",
    body,
  });

  if (response.ok) {
    return response.data.countryRegex;
  }

  throw new Error(response.errorMessage);
};

export const createRegex = async (
  body: ICreateCoutryRegex
): Promise<TPagingResponse<ICountryRegex>> => {
  const response = await request({
    path: "/api/v1/user-service/countryregex",
    method: "POST",
    body,
  });

  if (response.ok) {
    return response.data.countryRegex;
  }

  throw new Error(response.errorMessage);
};

export const deleteRegex = async (
  id: string
): Promise<TPagingResponse<ICountryRegex>> => {
  const response = await request({
    path: `/api/v1/user-service/countryregex/${id}`,
    method: "DELETE",
  });

  if (response.ok) {
    return response.data.countryRegex;
  }

  throw new Error(response.errorMessage);
};
