import { useCallback, useState } from "react";
import AwaitType from "../../../utils/AwaitType";
import { createOfficer, TCreateOfficerData, updateOfficer } from "../network";
import { IOfficerActionModeType } from "../models";

export type TResult = null | AwaitType<ReturnType<typeof createOfficer>>;

export const useOfficerAction = (): [
  TResult,
  boolean,
  Error | null,
  (
    data: TCreateOfficerData,
    mode: IOfficerActionModeType,
    officerId?: string
  ) => void,
  () => void
] => {
  const [result, setResult] = useState<TResult>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (
      data: TCreateOfficerData,
      mode: IOfficerActionModeType,
      officerId?: string
    ) => {
      setIsLoading(true);
      setError(null);

      try {
        const response =
          mode === "create"
            ? await createOfficer(data)
            : await updateOfficer(officerId!, data);
        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error("Unknown error"));
        }
      }
      setIsLoading(false);
    },
    []
  );

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return [result, isLoading, error, request, clearError];
};
