import { FC, memo, useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { CreateCompanyDialog } from "../../components/CreateCompanyDialog";
import { DataGrid, FunctionViewField, ListContainer } from "../../../ui";
import { ImageFile } from "../../../ui/components/FileBlock/styles";
import { getStatusIcon, statusesModel } from "../../utils";
import { IntegrationConfigDialog } from "../../components/IntegrationConfigDialog";
import { getApplicationsList } from "../../network";
import { useCompanyAuth } from "../../hooks/useCompanyAuth";
import { ICompanyActionModeType, TCompany } from "../../models";
import { TApp } from "../../../app/models";

interface ICompanyListProps {
  companies: any;
  reload: () => void;
  error: Error | null;
}

export const CompanyListScreen: FC<ICompanyListProps> = memo(
  ({ companies, reload, error }) => {
    const [isActionOpen, setIsActionOpen] = useState(false);
    const [actionMode, setActionMode] = useState<ICompanyActionModeType>("");
    const [selectedCompany, setSelectedCompany] = useState<TCompany | null>(
      null
    );
    const [isIntegrationOpen, setIsIntegrationOpen] = useState(false);
    const [companyResult, loading, , request, isAuthorized, authHandler] =
      useCompanyAuth();
    const [appList, setAppList] = useState<TApp[] | []>([]);

    const handleOpenAction = useCallback(
      (mode: ICompanyActionModeType, company?: any) => {
        setActionMode(mode);
        if (company) {
          setSelectedCompany(company);
        }
        setIsActionOpen(true);
      },
      [setIsActionOpen]
    );

    const handleCloseCreate = useCallback(
      (success: boolean) => {
        setIsActionOpen(false);
        setSelectedCompany(null);
        if (success) {
          reload();
        }
      },
      [setIsActionOpen, reload]
    );

    useEffect(() => {
      if (companyResult) {
        getApplicationsList()
          .then((appList) => setAppList(appList))
          .then(() => setIsIntegrationOpen(true));
      }
    }, [companyResult]);

    const integrationConfigHandler = async (record: TCompany) => {
      setSelectedCompany(record);
      authHandler(record, record.id);
    };

    return (
      <>
        <Stack direction='row' spacing={2} justifyContent='end'>
          <Button
            onClick={() => handleOpenAction("create")}
            variant='contained'
          >
            Create company
          </Button>
        </Stack>
        <ListContainer data={companies?.items}>
          <DataGrid>
            <FunctionViewField
              label='Name'
              source='name'
              render={(record: TCompany) => (
                <Box>
                  <Typography variant='h6'>{record.name}</Typography>
                </Box>
              )}
            />
            <FunctionViewField
              label='ID'
              source='id'
              render={(record: TCompany) => (
                <Box>
                  <Typography variant='body2'>{record.id}</Typography>
                </Box>
              )}
            />
            <FunctionViewField
              label='Purpose'
              source='purpose'
              render={(record: TCompany) => (
                <Box>
                  <Typography variant='body2'>{record.purpose}</Typography>
                </Box>
              )}
            />
            <FunctionViewField
              label='Status'
              source='status'
              render={(record: TCompany) => (
                <Stack
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  spacing={1}
                  minWidth={"200px"}
                >
                  <Box
                    sx={{
                      padding: "5px 10px",
                      background:
                        statusesModel[String(record.isActive)]?.bgColor,
                      borderRadius: "6px",
                    }}
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      position='relative'
                    >
                      <Box position='absolute' top='0' left='0'>
                        {getStatusIcon(record.isActive)}
                      </Box>
                      <Typography
                        variant='body2'
                        sx={{
                          paddingLeft: "25px",
                          fontWeight: "700",
                          color:
                            statusesModel[String(record.isActive)]?.textColor,
                        }}
                      >
                        {statusesModel[String(record.isActive)]?.name}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              )}
            />
            <FunctionViewField
              label='Logo'
              source='picture'
              render={(record: TCompany) => (
                <Box>
                  <ImageFile src={record.picture} />
                </Box>
              )}
            />
            <FunctionViewField
              label='Actions'
              source='actions'
              render={(record: TCompany) => (
                <Box>
                  <Button onClick={() => handleOpenAction("edit", record)}>
                    Edit
                  </Button>
                  <Tooltip
                    arrow
                    classes={{ arrow: "arrow", tooltip: "tooltip" }}
                    placement={"top-end"}
                    title={!record.isActive ? "Company must be active" : ""}
                  >
                    <span>
                      <Button
                        disabled={!record.isActive}
                        onClick={() => integrationConfigHandler(record)}
                      >
                        Integration Configuration
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              )}
            />
          </DataGrid>
        </ListContainer>
        {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
        <CreateCompanyDialog
          selectedCompany={selectedCompany}
          actionMode={actionMode}
          open={isActionOpen}
          onClose={handleCloseCreate}
        />
        <IntegrationConfigDialog
          selectedCompany={selectedCompany}
          appList={appList}
          setAppList={setAppList}
          open={isIntegrationOpen}
          onClose={() => setIsIntegrationOpen(false)}
        />
      </>
    );
  }
);
