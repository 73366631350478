import { Box, Typography } from "@mui/material";
import { FilledTextArea } from "../../../../components/FilledTextArea";
import countries from "../../../../data/countries.json";
import { ICountryRegex } from "../../../regexConfig/models";

export const FinancialInformation = (props: {
  onChange: any;
  name: string;
  fields: any;
  label: string;
  disabled?: boolean;
  countriesRegex: any;
}) => {
  const { onChange, fields, name, label, disabled, countriesRegex } = props;
  if (name === "tax_id") {
    try {
      const taxIds = JSON.parse(fields[name]);

      return (
        <>
          {taxIds.map((taxId: any) => {
            const regexCountry = Object.keys(taxId)[0];
            const viewCountry =
              countries.find((countryItem) => countryItem.code === regexCountry)
                ?.name || regexCountry;

            const regexConfig = countriesRegex[regexCountry] as ICountryRegex;
            const regex = regexConfig?.regexp
              ? new RegExp(regexConfig.regexp)
              : "";
            const check =
              regex && taxId[regexCountry]
                ? regex.test(taxId[regexCountry])
                : "none";
            const value = taxId[regexCountry];
            return (
              <Box>
                <FilledTextArea
                  sx={{
                    label: {
                      color:
                        check === "none"
                          ? null
                          : check
                          ? "green !important"
                          : "orange !important",
                    },
                  }}
                  multiline={true}
                  name={name}
                  label={
                    `${viewCountry} ${label}` +
                    (check ? "" : " (check the format)")
                  }
                  inputProps={{ onChange, disabled }}
                  value={value ?? ""}
                />
                <Typography variant='caption' paddingLeft='12px'>
                  {regexConfig?.example ?? ""}
                </Typography>
              </Box>
            );
          })}
        </>
      );
    } catch (e) {
      console.error("Error: ", e);
    }
  }
  return (
    <FilledTextArea
      multiline={true}
      name={name}
      label={label}
      inputProps={{ onChange, disabled }}
      value={fields[name] ?? ""}
    />
  );
};
