import { FC, useState } from "react";
import {
  Stack,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Alert,
} from "@mui/material";
import { FunnelIcon, ReloadIcon, SearchIcon, TrashIcon } from "../MuiIcons";
import { ClearButton, FilterButton, FilterPopover, HeadButton } from "./styles";
import { InputStyled } from "../SearchInput/styles";
import { TId } from "../../modules/ui";
import { KeyboardEventKey } from "../../constants";

export const HeadButtons: FC<{
  onChangeFilter: (value: string[]) => void;
  goToVerification: (id: TId, fromFilter?: boolean) => void;
  onReload: () => void;
  isAnotherCompany: boolean;
  setIsAnotherCompany: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  onChangeFilter,
  onReload,
  goToVerification,
  isAnotherCompany = false,
  setIsAnotherCompany,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedFilters, setSelectedFilters] = useState({
    ACCEPTED: false,
    CANCELED: false,
    NEW: false,
    SENT_TO_VERIFICATION: false,
    INFORMATION_REQUESTED: false,
  });
  const [verificationIdFilter, setVerificationIdFilter] = useState("");

  const handleFilterBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFiltersClose = () => {
    setAnchorEl(null);
  };

  const onSaveFilters = () => {
    if (verificationIdFilter) {
      goToVerification(verificationIdFilter, true);
      return;
    }
    const newFilters = Object.entries(selectedFilters)
      .filter((elem) => elem[1])
      .map((elem) => elem[0]);
    onChangeFilter(newFilters);
    handleFiltersClose();
  };

  const onClearFilters = () => {
    setSelectedFilters({
      ACCEPTED: false,
      CANCELED: false,
      NEW: false,
      SENT_TO_VERIFICATION: false,
      INFORMATION_REQUESTED: false,
    });
    setVerificationIdFilter("");
    onChangeFilter([]);
    setIsAnotherCompany(false);
    handleFiltersClose();
  };

  const handleChangeFilter = (event: any) => {
    setVerificationIdFilter("");
    setIsAnotherCompany(false);
    setSelectedFilters({
      ...selectedFilters,
      [event?.target?.name]: event?.target?.checked,
    });
  };
  const handleChangeVerificartionIdFilter = (event: any) => {
    setSelectedFilters({
      ACCEPTED: false,
      CANCELED: false,
      NEW: false,
      SENT_TO_VERIFICATION: false,
      INFORMATION_REQUESTED: false,
    });
    setIsAnotherCompany(false);
    setVerificationIdFilter(event?.target?.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Stack direction='row' spacing={1}>
        <HeadButton
          aria-describedby={id}
          variant='text'
          onClick={handleFilterBtnClick}
          startIcon={<FunnelIcon />}
        >
          Filter
        </HeadButton>
        <HeadButton
          variant='text'
          startIcon={<ReloadIcon />}
          onClick={onReload}
        >
          Refresh
        </HeadButton>
      </Stack>
      <FilterPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFiltersClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onKeyDown={(e) => {
          if (e.key === KeyboardEventKey.ENTER) onSaveFilters();
        }}
      >
        <Typography sx={{ mb: 1 }} fontWeight='bold'>
          Verification ID
        </Typography>
        <InputStyled
          placeholder='Search ID'
          value={verificationIdFilter}
          onChange={handleChangeVerificartionIdFilter}
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          }
        />
        {isAnotherCompany ? (
          <Alert
            sx={{ padding: "4px 12px 4px 8px !important", mt: 1 }}
            severity='warning'
          >
            This verification belongs to another company
          </Alert>
        ) : null}
        <Typography sx={{ mt: 2 }} fontWeight='bold'>
          Status
        </Typography>
        <FormGroup sx={{ mb: 2 }}>
          <FormControlLabel
            control={<Checkbox />}
            label='Accepted'
            name={"ACCEPTED"}
            checked={selectedFilters.ACCEPTED}
            onChange={handleChangeFilter}
          />
          <FormControlLabel
            control={<Checkbox />}
            label='Rejected'
            name={"CANCELED"}
            checked={selectedFilters.CANCELED}
            onChange={handleChangeFilter}
          />
          <FormControlLabel
            control={<Checkbox />}
            label='New'
            name={"NEW"}
            checked={selectedFilters.NEW}
            onChange={handleChangeFilter}
          />
          <FormControlLabel
            control={<Checkbox />}
            label='Sent to verification'
            name={"SENT_TO_VERIFICATION"}
            checked={selectedFilters.SENT_TO_VERIFICATION}
            onChange={handleChangeFilter}
          />
          <FormControlLabel
            control={<Checkbox />}
            label='Information requested'
            name={"INFORMATION_REQUESTED"}
            checked={selectedFilters.INFORMATION_REQUESTED}
            onChange={handleChangeFilter}
          />
        </FormGroup>
        <Stack sx={{ width: 320 }} direction='row' spacing={2}>
          <ClearButton
            variant='contained'
            startIcon={<TrashIcon />}
            onClick={onClearFilters}
          >
            Clear filter
          </ClearButton>
          <FilterButton variant='contained' onClick={onSaveFilters}>
            {verificationIdFilter ? "Go to Verification" : "Save"}
          </FilterButton>
        </Stack>
      </FilterPopover>
    </>
  );
};
