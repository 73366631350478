import { memo, useCallback, useState } from "react";
import { Alert, Box, Button, Typography } from "@mui/material";
import {
  CreateButton,
  DataGrid,
  FunctionViewField,
  ListContainer,
} from "../../../ui";
import { useRegexList } from "../../hooks/useRegexList";
import PageLayout from "../../../../components/PageLayout";
import { ICountryRegex } from "../../../company/models";
import { getCountryFromCode } from "../../../../utils/getCountryFromCode";
import { getLocaleStringdDate } from "../../../../utils/dateFormatt";
import { CreateRegexDialog } from "../../components/CreateRegexDialog";
import { deleteRegex } from "../../network";

export const RegexConfigListScreen = memo(() => {
  const [data, availCountries, loading, error, reload] = useRegexList();
  const [isActionDialogOpen, setIsActionDialogOpen] = useState(false);
  const [actionType, setActionType] = useState<"edit" | "create">("create");
  const [selectedRegex, setSelectedRegex] = useState<ICountryRegex | null>(
    null
  );

  const handleDeleteRegex = async (id: string) => {
    await deleteRegex(id);
    reload();
  };

  const handleOpenAction = useCallback(
    (type: "edit" | "create", regex?: ICountryRegex) => {
      setActionType(type);
      if (regex) {
        setSelectedRegex(regex);
      }
      setIsActionDialogOpen(true);
    },
    [setIsActionDialogOpen]
  );

  const handleCloseCreate = useCallback(
    (success: boolean) => {
      setIsActionDialogOpen(false);
      if (success) {
        reload();
      }
    },
    [setIsActionDialogOpen, reload]
  );

  return (
    <PageLayout
      title='Configuration'
      loading={loading}
      empty={error === null && (data === null || data.items.length === 0)}
      actions={
        <CreateButton onClick={() => handleOpenAction("create")}>
          New regex
        </CreateButton>
      }
    >
      {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
      <ListContainer data={data?.items} isLoading={loading}>
        <DataGrid>
          <FunctionViewField
            label='Country'
            source='country'
            render={(record: ICountryRegex) => (
              <Box>
                <Typography variant='h6'>
                  {getCountryFromCode(record?.country)}
                </Typography>
                <Typography variant='body2'>{record?.country}</Typography>
              </Box>
            )}
          />
          <FunctionViewField
            label='Regex'
            source='regex'
            render={(record: ICountryRegex) => (
              <Box>
                <Typography variant='body2'>{record?.regexp}</Typography>
              </Box>
            )}
          />
          <FunctionViewField
            label='Example'
            source='example'
            render={(record: ICountryRegex) => (
              <Box>
                <Typography variant='body2'>{record?.example}</Typography>
              </Box>
            )}
          />
          <FunctionViewField
            label='Type'
            source='type'
            render={(record: ICountryRegex) => (
              <Box>
                <Typography variant='body2'>{record?.type}</Typography>
              </Box>
            )}
          />
          <FunctionViewField
            label='Created at'
            source='created'
            render={(record: ICountryRegex) => (
              <Box>
                <Typography variant='body2'>
                  {getLocaleStringdDate(record?.createdAt)}
                </Typography>
              </Box>
            )}
          />
          <FunctionViewField
            label='Updated at'
            source='updated'
            render={(record: ICountryRegex) => (
              <Box>
                <Typography variant='body2'>
                  {getLocaleStringdDate(record?.updatedAt)}
                </Typography>
              </Box>
            )}
          />
          <FunctionViewField
            label='Actions'
            source='actions'
            render={(record: ICountryRegex) => (
              <Box>
                <Button onClick={() => handleOpenAction("edit", record)}>
                  Edit
                </Button>
                <Button onClick={() => handleDeleteRegex(record.id)}>
                  Delete
                </Button>
              </Box>
            )}
          />
        </DataGrid>
      </ListContainer>
      <CreateRegexDialog
        actionType={actionType}
        selectedRegex={selectedRegex}
        availCountries={availCountries}
        open={isActionDialogOpen}
        onClose={handleCloseCreate}
      />
    </PageLayout>
  );
});
