import { FC, memo, useCallback, useMemo, useState } from "react";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, FunctionViewField, ListContainer } from "../../../ui";
import { IOfficerActionModeType, TCompany, TOfficer } from "../../models";
import { CreateOfficerDialog } from "../../components/CreateOfficerDialog";
import { TPagingResponse } from "../../../../models/PagingResponse";
import { getCompaniesNameFromIds } from "../../utils";
import { ComplianceFilter } from "../../components/ComplianceFilter";

interface IComplianceListProps {
  officers: TPagingResponse<TOfficer>;
  companies: TPagingResponse<TCompany>;
  reload: () => void;
  error: Error | null;
}

export const ComplianceListScreen: FC<IComplianceListProps> = memo(
  ({ officers, companies, reload, error }) => {
    const [selectedCompany, setSelectedCompany] = useState<string>("");
    const [isActionOpen, setIsActionOpen] = useState(false);
    const [actionMode, setActionMode] =
      useState<IOfficerActionModeType>("create");
    const [selectedOfficer, setSelectedOfficer] = useState<TOfficer | null>(
      null
    );

    const handleOpenAction = useCallback(
      (mode: IOfficerActionModeType, officer?: any) => {
        setActionMode(mode);
        if (officer) {
          setSelectedOfficer(officer);
        }
        setIsActionOpen(true);
      },
      [setIsActionOpen]
    );

    const handleCloseCreate = useCallback(
      (success: boolean) => {
        setIsActionOpen(false);
        setSelectedOfficer(null);
        if (success) {
          reload();
        }
      },
      [setIsActionOpen, reload]
    );

    const filteredOfficers = useMemo(() => {
      return selectedCompany
        ? {
            items: officers.items.filter((item) =>
              item.companyId.includes(selectedCompany)
            ),
          }
        : officers;
    }, [officers, selectedCompany]);

    return (
      <>
        <Stack direction='row' spacing={2} justifyContent='end'>
          <ComplianceFilter
            companies={companies}
            onChangeFilter={setSelectedCompany}
          />
          <Button
            onClick={() => handleOpenAction("create")}
            variant='contained'
          >
            Create officer
          </Button>
        </Stack>
        <ListContainer data={filteredOfficers?.items}>
          <DataGrid>
            <FunctionViewField
              label='E-mail'
              source='email'
              render={(record: TOfficer) => (
                <Box>
                  <Typography variant='h6'>{record.email}</Typography>
                </Box>
              )}
            />
            <FunctionViewField
              label='ID'
              source='id'
              render={(record: TOfficer) => (
                <Box>
                  <Typography variant='body2'>{record.id}</Typography>
                </Box>
              )}
            />
            <FunctionViewField
              label='Phone'
              source='phone'
              render={(record: TOfficer) => (
                <Box>
                  <Typography variant='body2'>{record.phone}</Typography>
                </Box>
              )}
            />
            <FunctionViewField
              label='Companies'
              source='companies'
              render={(record: TOfficer) => (
                <Box>
                  <Typography variant='body2'>
                    {getCompaniesNameFromIds(companies.items, record.companyId)}
                  </Typography>
                </Box>
              )}
            />
            <FunctionViewField
              label='Actions'
              source='actions'
              render={(record: TOfficer) => (
                <Box>
                  <Button onClick={() => handleOpenAction("onlyEdit", record)}>
                    Edit
                  </Button>
                </Box>
              )}
            />
          </DataGrid>
        </ListContainer>
        {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
        <CreateOfficerDialog
          selectedOfficer={selectedOfficer}
          setSelectedOfficer={setSelectedOfficer}
          setActionMode={setActionMode}
          companiesList={companies}
          actionMode={actionMode}
          open={isActionOpen}
          onClose={handleCloseCreate}
        />
      </>
    );
  }
);
