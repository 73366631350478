import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LockedIcon } from "../../../../components/MuiIcons";
import { useCompanyAuth } from "../../hooks/useCompanyAuth";
import { AUTH_COMPANY_ID, TCompany } from "../../models";
import { CompanyCard, CompanyID, CompanyName } from "./styles";

export const CompanyAuthButton = memo(({ item }: { item: TCompany }) => {
  const { id, isActive, name } = item;

  const authorizedCompanyId = localStorage.getItem(AUTH_COMPANY_ID);
  const [result, loading, error, request, isAuthorized, authHandler] =
    useCompanyAuth(id);

  const navigate = useNavigate();

  useEffect(() => {
    result && navigate("/verification-requests/page/1");
  }, [result, navigate]);

  let icon = null;

  if (isAuthorized) {
    icon = <CheckCircleIcon />;
  } else if (loading) {
    icon = <CircularProgress />;
  } else {
    icon = <LockIcon />;
  }

  return (
    <CompanyCard
      disabled={!isActive}
      onClick={() => authHandler(item)}
      selected={authorizedCompanyId === id}
    >
      <Box>
        <CompanyName>{name}</CompanyName>
      </Box>
      <Box marginTop='4px'>
        <CompanyID>{id}</CompanyID>
      </Box>
      {!isActive && (
        <LockedIcon
          sx={{
            position: "absolute",
            right: "20px",
            top: "50%",
            transform: "translate(0, -50%)",
          }}
        />
      )}
    </CompanyCard>
  );
});
