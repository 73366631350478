import { useCallback, useEffect } from "react";
import { ConfirmationDialog } from "../../../ui/components/ConfirmationDialog";
import { useSendToVerification } from "../../hooks/useSendToVerification";

export type Props = {
  onClose: (reason: "sendToVerify" | "cancel") => void;
  open: boolean;
  verificationId: string;
};

export const SendToVerificationDialog = (props: Props) => {
  const { onClose, open, verificationId } = props;
  const [sendingResult, sendingLoading, sendingError, sendToVerification] =
    useSendToVerification();

  useEffect(() => {
    if (sendingResult) {
      onClose("sendToVerify");
    }
  }, [sendingResult, onClose]);

  const handleOk = useCallback(() => {
    sendToVerification(verificationId);
  }, [verificationId, sendToVerification]);

  return (
    <ConfirmationDialog
      title='Are you sure?'
      description={
        <span>
          After this action, the entry status will be changed to
          <span style={{ color: "#D2B102" }}> sent to verification</span>
        </span>
      }
      error={sendingError}
      confirmText='Send'
      confirmAction={handleOk}
      disabled={sendingLoading}
      onClose={onClose}
      isOpen={open}
      confirmButtonColor='primary'
    />
  );
};
