import { FC, memo, useEffect } from "react";
import { Alert } from "@mui/material";
import PageLayout from "../../../../components/PageLayout";
import { useActiveTabs } from "../../../../utils/useActiveTabs";
import { Box } from "@mui/system";
import { PageTabs } from "../../../../components/PageTabs";
import { useCompaniesList } from "../../../company/hooks/useCompaniesList";
import { CompanyListScreen } from "../CompanyListScreen";
import { ComplianceListScreen } from "../ComplianceListScreen";
import { useOfficersList } from "../../hooks/useOfficersList";
import { useAuth } from "../../../../auth/useAuth";
import { AppListScreen } from "../../../app/screens/AppListScreen";
import { useLocation, useNavigate } from "react-router-dom";

const tabList = [
  { id: 0, label: "Companies" },
  { id: 1, label: "Compliance officers" },
  { id: 2, label: "Apps" },
];

export const ComplianceScreen: FC = memo(() => {
  const [data, loading, error, reload] = useCompaniesList();
  const { companies } = useAuth();
  const [officers, officersLoading, officersError, officersReload] =
    useOfficersList();
  const { activeTab, handleChange } = useActiveTabs();
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash.split("#")[1] === "2") {
      handleChange(null, 2);
      navigate("/companies", { replace: true });
    }
  }, [hash, handleChange, navigate]);

  return (
    <PageLayout
      title='Companies Configuration'
      loading={loading}
      empty={
        error === null && (companies === null || companies.items.length === 0)
      }
      subheader={
        <>
          <Box flexGrow='1'>
            <PageTabs
              tabs={tabList}
              value={activeTab}
              onChange={handleChange}
            />
          </Box>
        </>
      }
    >
      <Box sx={{ width: "100%" }}>
        {activeTab === 0 && (
          <>
            {error !== null && (
              <Alert severity='error'>{error.toString()}</Alert>
            )}
            {companies && (
              <CompanyListScreen
                error={error}
                reload={reload}
                companies={companies}
              />
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            {officersError !== null && (
              <Alert severity='error'>{officersError.toString()}</Alert>
            )}
            {officers && (
              <ComplianceListScreen
                error={error}
                reload={officersReload}
                companies={companies ?? { items: [] }}
                officers={officers}
              />
            )}
          </>
        )}
        {activeTab === 2 && <AppListScreen />}
      </Box>
    </PageLayout>
  );
});
