import { useCallback, useEffect, useState } from "react";
import { getOfficersList } from "../network";
import { TOfficer } from "../models";
import { TPagingResponse } from "../../../models/PagingResponse";

export const useOfficersList = (): [
  TPagingResponse<TOfficer> | null,
  boolean,
  Error | null,
  () => void
] => {
  const [result, setResult] = useState<TPagingResponse<TOfficer> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getOfficersList();
      setResult(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error("Unknown error"));
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    request();
  }, [request]);

  return [result, isLoading, error, request];
};
