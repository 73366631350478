import { fromBase64 } from "../../../utils/base64";
import { TDocument, TVerificationRequest } from "../models";
import countries from "../../../data/countries.json";

export const mapVerificationRequestIn = (
  data: TVerificationRequest
): TVerificationRequest => {
  if (data.additionalItems?.user_information) {
    data.additionalItems.userInformationParsed = JSON.parse(
      fromBase64(data.additionalItems.user_information)
    );
  }

  return data;
};

const countryFields = ["country_of_birth", "nationality", "country"];
const booleanFields = ["ownership_confirmation", "is_pep"];

export const mapDocument = (data: any): TDocument => {
  Object.keys(data).forEach((key) => {
    if (countryFields.includes(key)) {
      if (data[key]) {
        const country = countries.find((country) => country.code === data[key]);
        data[key] = country?.name;
      }
    }
    if (booleanFields.includes(key)) {
      data[key] = data[key] ? "Yes" : "No";
    }
  });

  return data;
};
