import { memo, useCallback, useEffect, useMemo } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField as MuiTextField,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useAppSettings } from "../../hooks/useAppSettings";
import { useAppSettingsUpdate } from "../../hooks/useAppSettingsUpdate";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { TAppSettings } from "../../models";

export const AppSettingsCard = memo((props: { appId: string }) => {
  const { appId } = props;
  const [data, loading, error] = useAppSettings(appId);
  const [updateResult, updateLoading, updateError, update] =
    useAppSettingsUpdate();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = useCallback(
    (formValues: TAppSettings) => {
      try {
        const newConfig = {
          onboardingConfig: JSON.stringify(
            JSON.parse(formValues?.onboardingConfig!),
            null,
            2
          ),
        };
        update(appId, newConfig);
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [appId, update, enqueueSnackbar]
  );

  useEffect(() => {
    if (updateResult) {
      enqueueSnackbar("App settings updated", { variant: "success" });
    }
  }, [enqueueSnackbar, updateResult]);

  const initialValue = useMemo(() => {
    try {
      return {
        onboardingConfig: JSON.stringify(
          JSON.parse(updateResult?.onboardingConfig || data?.onboardingConfig),
          null,
          2
        ),
      };
    } catch (e) {
      return updateResult || data;
    }
  }, [updateResult, data]);

  return (
    <Box mb={2}>
      <Card>
        <CardHeader
          title='App settings'
          action={loading ? <CircularProgress size={25} /> : null}
        />
        <CardContent>
          <Stack spacing={2} direction='row'>
            <MuiTextField
              fullWidth
              multiline
              name='default'
              label='Default Config Example'
              value={JSON.stringify(
                {
                  type: "FinancialInformation",
                  "excludedFields (not mandatory)": [
                    "expected_volume_of_transactions",
                  ],
                  config: {
                    elements: [
                      {
                        type: "FinancialInformation",
                      },
                    ],
                  },
                },
                null,
                2
              )}
            />
            <MuiTextField
              fullWidth
              multiline
              name='default'
              label='Fields Config Example'
              value={JSON.stringify(
                {
                  type: "UserInformation",
                  config: {
                    fields: [
                      {
                        name: "field_name",
                        label: "Field label",
                        type: "countrySelect | select | text | date | filePicker",
                        ["subtitle (not mandatory)"]: "field subtitle",
                        required: true,
                      },
                    ],
                  },
                },
                null,
                2
              )}
            />
          </Stack>
        </CardContent>
        <Form
          initialValues={initialValue}
          onSubmit={onSubmit}
          render={({ handleSubmit, dirty }) => (
            <form onSubmit={handleSubmit}>
              <CardContent>
                {error !== null && (
                  <Alert severity='error'>{error.toString()}</Alert>
                )}
                <TextField name='onboardingConfig' label='Config' multiline />
                {updateError !== null && (
                  <Alert severity='error'>{updateError.toString()}</Alert>
                )}
              </CardContent>
              <CardActions>
                <Button type='submit' disabled={updateLoading || !dirty}>
                  Save
                </Button>
              </CardActions>
            </form>
          )}
        />
      </Card>
    </Box>
  );
});
