import { useState } from "react";

export const useActiveTabs = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number
  ): void => {
    setActiveTab(newValue);
  };

  return { activeTab, handleChange };
};
