import { useCallback, useState } from "react";
import AwaitType from "../../../utils/AwaitType";
import { createRegex, updateRegex } from "../network";
import { ICreateCoutryRegex } from "../../company/models";

export type TResult = null | AwaitType<ReturnType<typeof createRegex>>;

export const useRegexActions = (): [
  TResult,
  boolean,
  Error | null,
  (data: ICreateCoutryRegex, action: "create" | "edit", id: string) => void
] => {
  const [result, setResult] = useState<TResult>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (data: ICreateCoutryRegex, action: "create" | "edit", id: string) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await (action === "create"
          ? createRegex(data)
          : updateRegex(id, data));
        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error("Unknown error"));
        }
      }
      setIsLoading(false);
    },
    []
  );

  return [result, isLoading, error, request];
};
