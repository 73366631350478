import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FC } from "react";

interface IDeclineReasonForm {
  reason: string;
  otherReason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  setOtherReason: React.Dispatch<React.SetStateAction<string>>;
  validationErrors: any;
}

export const DeclineReasonForm: FC<IDeclineReasonForm> = ({
  reason,
  otherReason,
  setReason,
  setOtherReason,
  validationErrors,
}) => {
  return (
    <>
      <FormControl error={validationErrors.reason} required>
        <InputLabel>Reject reason</InputLabel>
        <Select
          variant='filled'
          name={"declineReason"}
          label={"Decline reason"}
          value={reason}
          renderValue={(value) => value}
          onChange={(e: any) => {
            delete validationErrors.reason;
            setReason(e.target.value);
          }}
        >
          {[
            "Incomplete File",
            "Inconsistent Data",
            "Wrong Region",
            "Criminal Record",
            "Sanctions",
            "PEP",
            "Adverse Media",
            "Other",
          ].map((reason) => (
            <MenuItem value={reason} key={reason}>
              {reason}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        error={validationErrors.otherReason}
        label='Specified'
        name='other'
        required={reason === "Other"}
        size='small'
        margin='normal'
        value={otherReason}
        inputProps={{
          onChange: (e: any) => {
            delete validationErrors.otherReason;
            setOtherReason(e.target.value);
          },
        }}
      />
    </>
  );
};
