import { useCallback, useState } from "react";
import { changeDeclineReasonRequest } from "../network";

export const useChangeRejectReason = (): [
  any | null,
  boolean,
  Error | null,
  (requestId: string, statusReason: string) => void
] => {
  const [result, setResult] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (requestId: string, statusReason: string) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await changeDeclineReasonRequest(
          requestId,
          statusReason
        );
        setResult(response);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error("Unknown error"));
        }
      }

      setIsLoading(false);
    },
    []
  );

  return [result, isLoading, error, request];
};
