import { isEmpty } from "lodash";
import { useState } from "react";

export const useRejectReason = (
  reason: string,
  otherReason: string,
  verificationId: string,
  cb: any
): [() => void, any, React.Dispatch<any>] => {
  const [validationErrors, setValidationErrors] = useState<any>({});

  const request = () => {
    const errors: any = {};
    if (!reason) {
      errors.reason = true;
    }
    if (reason === "Other" && !otherReason) {
      errors.otherReason = true;
    }

    if (isEmpty(errors)) {
      const complexReason = otherReason ? `${reason}: ${otherReason}` : reason;
      cb(verificationId, complexReason);
    } else {
      setValidationErrors(errors);
    }
  };
  return [request, validationErrors, setValidationErrors];
};
