import { memo } from "react";
import { useParams } from "react-router-dom";
import { Alert, Typography } from "@mui/material";
import PageLayout from "../../../../components/PageLayout";
import { useApp } from "../../hooks/useApp";
import { AppSettingsCard } from "../../components/AppSettingsCard";

export const AppScreen = memo(() => {
  const { id } = useParams<{ id: string }>();
  const [data, loading, error] = useApp(id);

  return (
    <PageLayout
      title={`App | ${data ? data.name : id}`}
      loading={loading}
      empty={error === null && data === null}
      backUrl={`/companies#2`}
      onlyUrl
    >
      {error !== null && <Alert severity='error'>{error.toString()}</Alert>}

      {data && (
        <div>
          <Typography component='p' gutterBottom>
            Public key: {data.publicKey || "-"}
          </Typography>
          <AppSettingsCard appId={id!} />
        </div>
      )}
    </PageLayout>
  );
});
