import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { SigninScreen } from "../../screens/SigninScreen";
import { AppContainer } from "../AppContainer";
import { UnauthorizedContainer } from "../UnauthorizedContainer";
import { VerificationRequestListScreen } from "../../modules/verificationRequest/screens/VerificationRequestListScreen";
import { VerificationRequestScreen } from "../../modules/verificationRequest/screens/VerificationRequestScreen";
import { useAuth } from "../../auth/useAuth";
import { AppScreen } from "../../modules/app/screens/AppScreen";
import { AuthorizedContainer } from "../AuthorizedContainer";
import { CompanySelectListScreen } from "../../modules/company/screens/CompanySelectListScreen";
import { ComplianceScreen } from "../../modules/company/screens/CompliensScreen";
import { RegexConfigListScreen } from "../../modules/regexConfig/screens/RegexConfigListScreen";

export const NavigationContainer: FC = () => {
  const { loading } = useAuth();

  if (loading) {
    return null;
  }

  return (
    <Routes>
      <Route path='/' element={<AuthorizedContainer />}>
        <Route path='/selectcompanies' element={<CompanySelectListScreen />} />
        <Route path='/' element={<AppContainer />}>
          <Route path='/companies' element={<ComplianceScreen />} />
          <Route path='/companies/app/:id' element={<AppScreen />} />
          <Route
            path='/verification-requests'
            element={<VerificationRequestListScreen />}
          />
          <Route
            path='/verification-requests/page/:pageNum'
            element={<VerificationRequestListScreen />}
          />
          <Route
            path='/verification-requests/:id'
            element={<VerificationRequestScreen />}
          />
          <Route path='/config' element={<RegexConfigListScreen />} />
        </Route>
      </Route>
      <Route path='/auth' element={<UnauthorizedContainer />}>
        <Route index element={<SigninScreen />} />
      </Route>
    </Routes>
  );
};
