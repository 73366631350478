import { useCallback, useEffect, useState } from "react";
import { TDocument, TVerificationRequest } from "../models";
import { getVerificationRequest } from "../network";
import { mapDocument } from "../mappers";
import { fromBase64 } from "../../../utils";

export type TResult = null | undefined | TDocument[];

export const useDocumentListWithData = (
  requestId: string
): [
  TResult,
  boolean,
  Error | null,
  TVerificationRequest | null,
  () => void
] => {
  const [result, setResult] = useState<TResult>(null);
  const [initialResult, setInitialResult] =
    useState<TVerificationRequest | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getVerificationRequest(requestId);
      setInitialResult(response);
      response?.documents!.forEach((doc) => {
        doc.data.forEach((x: any) => {
          if (x.dataBase64) {
            try {
              x.data = mapDocument(JSON.parse(fromBase64(x.dataBase64)));
            } catch (e) {
              console.error(e);
            }
          }
        });
      });
      const result = response.documents;
      setResult(result);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error("Unknown error"));
      }
    }
    setIsLoading(false);
  }, [requestId]);

  useEffect(() => {
    request();
  }, [request]);

  return [result, isLoading, error, initialResult, request];
};
